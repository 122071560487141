import React, { useMemo } from 'react';
import styles from './index.module.less';
import Image from 'next/image';

const HairstylesCreatedContent = ({ pageContent }: { pageContent?: any }) => {
  const showH2Memo = useMemo(() => {
    return pageContent?.contentInfo?.['section-customer']?.title
      .replace('XXX', '<span style="color: #68f3f6">67,342</span>')
      .replace('XXX', '<span style="color: #9772FF">1,539</span>');
  }, [pageContent]);

  return (
    <div className={styles.content}>
      <div className={styles.h2}>
        <span dangerouslySetInnerHTML={{ __html: showH2Memo }} />
      </div>

      <div className={styles.container}>
        {[
          '/images/hairstyle/home-created-customer-male.png',
          '/images/hairstyle/home-created-customer-female.png',
        ].map((item, indx) => {
          return (
            <Image
              className={styles.imgItem}
              key={item}
              src={item}
              alt="icon"
              loading="lazy"
              width={0}
              height={0}
              sizes="50vw"
            />
          );
        })}
      </div>
    </div>
  );
};

export default HairstylesCreatedContent;
